
const boxImageArray = (location) => {

  return(
    [
      location.px.filename,
      location.nx.filename,
      location.py.filename,
      location.ny.filename,
      location.pz.filename,
      location.nz.filename,
    ]
  )
}

export default boxImageArray