import React, { Suspense, useEffect, useState }  from 'react'
// import { 
//   useUpdate 
// } from '@react-three/fiber'
import * as THREE from 'three'
import { 
    Canvas
} from '@react-three/fiber'
import style from './julius.module.scss'

import JuliusScene from './juliusScene'
import AudioPlayer from '../../elements/audioPlayer'

import JuliusControls from './juliusControls'
// import Skybox from '../../threejs/skybox'
import boxImageArray from './helpers/boxImageArray'
import SkyBox2 from '../../threejs/skybox2'

function App({ juliusLocations }) {

  // handle smooth transition

  const dotHeight = -5

  // const offsHght1 = dotHeight + 110
  // const offsHght2 = dotHeight + 50
  const offsHght1 = dotHeight
  const offsHght2 = dotHeight

  let positions = [
    // OLD HALF WORKING!!
    [     0, dotHeight,    0, 0 ], //mercedeplatz 1
    [  -90,  dotHeight, -150, Math.PI / 2 * -0.2], //mercedeplatz 2
    [  -220, dotHeight, -280, Math.PI / 2 * 0.7 ], //mercedeplatz 3
    // [  -240, dotHeight, -280, Math.PI / 2 * 0.3 ], //mercedeplatz 3
    [  -250, dotHeight, -340,  Math.PI / 2 * 0.25 ], //mercedeplatz 4
    [  -260, dotHeight, -500, 0 ], //mercedeplatz 5
    [  -270, offsHght1, -600, 0 ], //mercedeplatz 6
    [  -280, dotHeight, -700, 0 ], //mercedeplatz 7
    [  -290, offsHght2, -800, Math.PI / 2 * -0.1 ], //mercedeplatz 8
    // 0: left/right
    // 1: up/down
    // 2: backwards/forwards
    // 4: offset rotation of the skybox
    // [     0, dotHeight,    0, 0 ], //mercedeplatz 1
    // [  -10, dotHeight, -150, Math.PI / 2 * 0.3 ], //mercedeplatz 2
    // [  -180, dotHeight, -140, Math.PI / 2 * 3.0 ], //mercedeplatz 3
    // [  -210, dotHeight, -450, 0 ], //mercedeplatz 4
    // [  -220, dotHeight, -850, 0 ], //mercedeplatz 5
    // [  -240, dotHeight + 10, -1150, 1 ], //mercedeplatz 6
    // [  -245, dotHeight, -1550, 0 ], //mercedeplatz 7
    // [  -250, dotHeight, -1850, 0 ], //mercedeplatz 8
  ]

  const [ state, setState] = useState({
    activeHoverIndex: false,
    activeCubeIndex: 0,
    locations: juliusLocations
  })

  const handleActiveView = (i) => {
    // console.log('set activeview index: ', i)
    setState({
      ...state,
      activeCubeIndex: i,
    })
  }

  const handleActiveHover = (i) => {
    // console.log('set activhover index: ', i)
    setState({
      ...state,
      activeHoverIndex: i,
    })
  }

  useEffect(() => {}, [])

  console.log(juliusLocations)

  return (
    <div className={style.julius}>
      <div className={style.juliusInfo}>

        {state.activeHoverIndex && juliusLocations[state.activeHoverIndex] &&
        <h2 className={style.juliusInfoTitle}>{juliusLocations[state.activeHoverIndex].title}</h2>
        }

        <div className={style.juliusInfoAudio}>

          {juliusLocations[state.activeCubeIndex] && juliusLocations[state.activeCubeIndex].audio.filename && <AudioPlayer
          color={"white"}
          onEnded={() => {}}
          onPlay={() => {}}
          file={juliusLocations[state.activeCubeIndex].audio.filename}
          autoPlay={true}
          handleOnClick={() => {}}
          />}
        </div>
          
      </div>
      <div className={style.juliusCanvasWrapper}>
        <Canvas camera={{ position: [0, 0, 10], fov: 75 }} className="canvas">
          <group 
          // rotation={[0,positions[state.activeCubeIndex][3],0]}
          >
            <JuliusScene
            activeCubeIndex={state.activeCubeIndex}
            locations={juliusLocations}
            positions={positions}
            handleActiveView={handleActiveView}
            // handleActiveHover={handleActiveHover}
            />
          
          </group>
          <JuliusControls 
          position={positions[state.activeCubeIndex]}
          />
          <Suspense fallback={null}>
            <SkyBox2 
            images={boxImageArray(juliusLocations[state.activeCubeIndex])} 
            offset={positions[state.activeCubeIndex][3]} 
            position={positions[state.activeCubeIndex]}
            />
          </Suspense>
          {/* <Skybox images={boxImageArray(juliusLocations[state.activeCubeIndex])} offset={positions[state.activeCubeIndex][3]} /> */}
        </Canvas>
      </div>
    </div>
  )
}
export default App;
