import React, { useEffect, useRef } from "react"
import { extend, useThree, useFrame } from '@react-three/fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// const GLTFLoader = require('three/examples/jsm/loaders/OBJLoader')

extend({ OrbitControls })

const Controls = ({ minPolarAngle, maxPolarAngle, enableZoom, position }) => {

    const orbitRef = useRef()
    const { camera, gl } = useThree()
    // camera.position.set(position[0], position[1], position[2])
        // position={position}
    // camera.target.set(0,0,0.4)


    useFrame(() => {
        if(orbitRef && orbitRef.current) orbitRef.current.update()
    })

    useEffect(() => {

      camera.position.set(position[0], position[1], position[2])
    //     console.log(orbitRef.current.object)
    //     // orbitRef.current.object.lookAt(400,400,404)
    //     orbitRef.current.object.updateProjectionMatrix()

    })

    // console.log('Controls rendered at', position)

    return(
        <orbitControls 
        args={[camera, gl.domElement]}
        ref={orbitRef}
        // minPolarAngle={minPolarAngle}
        // maxPolarAngle={maxPolarAngle}
        // enableZoom={false}
        enableZoom={true}
        // position={position ? position : [0, 100, 100]}
        // position={position}
        position={[position[0], position[1], position[2]]}
        // target={position}
        target={[position[0]-0.0001,position[1]-0,position[2]-0.01]} // target basically at the same position so that position doesnt move around
        // lookAt={[position[0],position[1],-100]}
        lookAt={[position[0], position[1], position[2]]}
        // autoRotate
        />
    )
}

export default Controls