import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Julius from "../../components/pages/julius/julius"
import AccessWrapper from '../../components/pages/AccessWrapper'

// console.log(list)

const JuliusPage = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)
  const juliusLocations = content.juliusLocations

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='Julius C. Schreiner' content={content.artists[11]}  id={11}>
        <Julius juliusLocations={juliusLocations}/>
      </AccessWrapper>
    </Layout>
  )
}

export default JuliusPage

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`