import React, { Suspense } from 'react'
import {  useFrame, useLoader, useThree } from '@react-three/fiber';
// import {  useThree } from 'react-three-fiber'
import { BoxBufferGeometry, CubeTextureLoader, TextureLoader } from "three";
import * as THREE from 'three'
import { useRef } from 'react';

// Loads the skybox texture and applies it to the scene.
function SkyBox2({ images, offset, position }) {

    // scene.rotateY(offset);
    // scene.rotateOnAxis(new THREE.Vector3(0, 1, 0), offset)

    const loader = new CubeTextureLoader();

    const texture = loader.load(images)
    texture.encoding = THREE.sRGBEncoding

    const mesh = useRef()
    // useFrame(state => {

    //   mesh.current.material.forEach(material => (material.uniforms.time.value = state.clock.getElapsedTime()))
    //   // mesh.current.rotation.x = mesh.current.rotation.y = mesh.current.rotation.z += 0.01
    // })
    const texture_1 = useLoader(TextureLoader, images[0])
    const texture_2 = useLoader(TextureLoader, images[1])
    const texture_3 = useLoader(TextureLoader, images[2])
    const texture_4 = useLoader(TextureLoader, images[3])
    const texture_5 = useLoader(TextureLoader, images[4])
    const texture_6 = useLoader(TextureLoader, images[5])

    texture_1.flipY = false
    texture_2.flipY = false
    texture_3.flipY = false
    texture_4.flipY = false
    texture_5.flipY = false
    texture_6.flipY = false
    // texture.center = new THREE.Vector2(0.5, 0.5);
    texture_1.center = new THREE.Vector2(0.5, 0.5)
    texture_2.center = new THREE.Vector2(0.5, 0.5)
    texture_3.center = new THREE.Vector2(0.5, 0.5)
    texture_4.center = new THREE.Vector2(0.5, 0.5)
    texture_5.center = new THREE.Vector2(0.5, 0.5)
    texture_6.center = new THREE.Vector2(0.5, 0.5)


    texture_1.rotation = Math.PI
    texture_2.rotation = Math.PI
    texture_3.rotation = Math.PI
    texture_4.rotation = Math.PI
    texture_5.rotation = Math.PI
    texture_6.rotation = Math.PI

    // texture_1.wrapS = THREE.RepeatWrapping
    // texture_2.wrapS = THREE.RepeatWrapping
    // texture_3.wrapS = THREE.RepeatWrapping
    // texture_4.wrapS = THREE.RepeatWrapping
    // texture_5.wrapS = THREE.RepeatWrapping
    // texture_6.wrapS = THREE.RepeatWrapping
    // texture_1.repeat.x = -1 
    // texture_2.repeat.x = -1 
    // texture_3.repeat.x = -1 
    // texture_4.repeat.x = -1 
    // texture_5.repeat.x = -1 
    // texture_6.repeat.x = -1 


    return (
        <mesh
        ref={mesh}
        position={[position[0], - 5, position[2]]} 
        // rotateOnAxis={["y", Math.PI / 3]}
        // rotateOnAxis={["y", position[3]]}
        rotation={[0,position[3],0]}
        // scale={[-1,0,0]}
        >
          <boxBufferGeometry
          attach="geometry"
          args={[1200, 1200, 1200]} 
          uvsNeedUpdate={true}
          scale={[-1,0,0]}
          />
          {/* <meshNormalMaterial color={'#333'} attach="material" side={THREE.DoubleSide}/> */}
          {/* <meshNormalMaterial map={images[0]} color={'#333'} attachArray="material" side={THREE.DoubleSide}/>
          <meshNormalMaterial map={images[1]} color={'#333'} attachArray="material" side={THREE.DoubleSide} />
          <meshNormalMaterial map={images[2]} color={'#333'} attachArray="material" side={THREE.DoubleSide} />
          <meshNormalMaterial map={images[3]} color={'#333'} attachArray="material" side={THREE.DoubleSide} />
          <meshNormalMaterial map={images[4]} color={'#333'} attachArray="material" side={THREE.DoubleSide} />
          <meshNormalMaterial map={images[5]} color={'#333'} attachArray="material" side={THREE.DoubleSide} /> */}
          <meshBasicMaterial
          map={texture_2}
          attachArray="material"
          side={THREE.BackSide}
          // opacity={0.5}
          // transparent={true}
          />
          <meshBasicMaterial
          map={texture_1}
          attachArray="material"
          side={THREE.BackSide}
          // opacity={0.5}
          // transparent={true}
          />
          <meshBasicMaterial
          map={texture_3}
          attachArray="material"
          side={THREE.BackSide}
          // opacity={0.5}
          // transparent={true}
          />
          <meshBasicMaterial
          map={texture_4}
          attachArray="material"
          side={THREE.BackSide}
          // opacity={0.5}
          // transparent={true}
          />
          <meshBasicMaterial
          map={texture_5}
          attachArray="material"
          side={THREE.BackSide}
          // opacity={0.5}
          // transparent={true}
          />
          <meshBasicMaterial
          map={texture_6}
          attachArray="material"
          side={THREE.BackSide}
          // opacity={0.5}
          // transparent={true}
          />
          {/* <meshNormalMaterial color={'#333'} attachArray="material" side={THREE.DoubleSide}/>
          <meshNormalMaterial color={'#333'} attachArray="material" side={THREE.DoubleSide} />
          <meshNormalMaterial color={'#333'} attachArray="material" side={THREE.DoubleSide} />
          <meshNormalMaterial color={'#333'} attachArray="material" side={THREE.DoubleSide} />
          <meshNormalMaterial color={'#333'} attachArray="material" side={THREE.DoubleSide} />
          <meshNormalMaterial color={'#333'} attachArray="material" side={THREE.DoubleSide} /> */}
        </mesh>
    )
}

export default SkyBox2

