
import React, { useEffect, useRef, useState } from 'react';

import * as THREE from 'three'
import { 
  Canvas, 
  extend, 
  useFrame
  
} from '@react-three/fiber'
import style from "./julius.module.scss"
// import { a } from 'react-spring/three'
import InteractiveDot from './interactiveDot'

// extend( 'EXT_color_buffer_float' );
extend( 'OES_texture_half_float' );
extend( 'OES_texture_half_float_linear' );


function JuliusScene({
  activeCubeIndex,
  locations,
  positions,
  handleActiveView,
  handleActiveHover
}) {


  let lightsYellow = [
    [-300, 0, -100],
    [-300, 0, -200],
    [-300, 0, -300],
    [-300, 0, -400],
    [-300, 0, -500],
    [-300, 0, -600],

    [300, 0, -100],
    [300, 0, -200],
    [300, 0, -300],
    [300, 0, -400],
    [300, 0, -500],
    [300, 0, -600],
  ];

  let lightsSky = [
    [0, 3000, 0],
    [0, 3000, -200],
  ];
  // const [ state, setState ] = useState({
  //   position: 
  // })

  // console.log('JuliusScene activeCubIndex: ', activeCubeIndex)

  // const activeLocations = positions.filter((loc, i) => { 
    
  //   console.log("i", i, "activeCubeIndex", activeCubeIndex, i !== activeCubeIndex)
  //   return i !== activeCubeIndex
  // })


  // const ref = useUpdate(group => {
  //   group.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI/2 * positions[activeCubeIndex][3])
  // }, [])

  const ref = useRef();

  // useEffect(() => {
  //   // if (ref.current && positions[activeCubeIndex][3]) ref.current.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI/2 * positions[activeCubeIndex][3])
  //   console.log("useeffect", ref.current && positions[activeCubeIndex][3])
  //   if (ref.current && positions[activeCubeIndex][3]) ref.current.y = positions[activeCubeIndex][3]
  // }, [activeCubeIndex])

  // useFrame(() => {
    
  //   // if (ref.current && positions[activeCubeIndex][3]) ref.current.rotateY(positions[activeCubeIndex][3])
  //   if (ref.current && positions[activeCubeIndex][3]) ref.current.rotate.y = positions[activeCubeIndex][3]
  // })


  return (
    <group
    // rotateOnAxis={positions[activeCubeIndex][3] !== 0 ? [new THREE.Vector3(0, 1, 0), positions[activeCubeIndex][3]] : null}
    >
      {/* <ambientLight intensity={0.1} /> */}
      {
        lightsSky.map((pos, i) => (
          <pointLight key={i} color={`rgb(100, 100, 255)`} intensity={1} position={pos} />
        ))
      }
      {
        lightsYellow.map((pos, i) => (
          <pointLight key={i} color={`rgb(255, 200, 120)`} intensity={1} position={pos} />
        ))
      }
      {
        positions.map((location, i) => {
        // activeLocations.map((location, i) => {
          
          /* Exclude current View */
          {/* if(i === activeCubeIndex ) return false */}
          if(i === activeCubeIndex || !positions[i]) return false

          // console.log(i, positions[i])
          /* const position = positions[i] || [0, 400, 0];  // dot in the sky if not found!! */
          const position = positions[i]

          return(
            <InteractiveDot
            index={i}
            key={i}
            // activeCubeIndex={activeCubeIndex}
            // position={position}
            position={position}
            handleActiveView={(e) => {
              e.stopPropagation();
              handleActiveView(i)}}
            // handleActiveHover={() => handleActiveHover(i)}
            />
          )
        })
      }
    </group>
  )
}

export default JuliusScene