import React, { useEffect, useState }  from 'react'
// import { a, useSpring } from 'react-spring/three'
import { a, useSpring } from '@react-spring/three'
import { useThree } from '@react-three/fiber';

const InteractiveDot = ({ position, handleActiveView, handleActiveHover, index }) => {
    // const totalPos = 6;
    // const colorTone = Math.floor(255-(index+1)/(totalPos+1)*255)

    const { scene } = useThree()

    const [ state, setState ] = useState({
        active: false
    })
    const handleOnPointerOver = (e) => {
        e.stopPropagation();

        console.log('Hover Index ', index, position)
        
        // console.log('hover over: ', index)
        if(handleActiveHover) handleActiveHover(index)
        setState({
            ...state,
            active: true
        })
    }
    const handleOnPointerOut = (e) => {
        e.stopPropagation();
        // console.log('hover out')
        if(handleActiveHover) handleActiveHover(false)
        setState({
            ...state,
            active: false
        })
    }

    const springProps = useSpring({
        // args: state.active ? [ 5, 50 ,50] : [1 ,50 , 50],
        // scale: state.active ? 1.5 : 1,
        scale: state.active ? [2, 2, 2] : [1, 1, 1],
        // config: { 
        //     tension: 170,
        //     mass: 1, // 10
        //     friction: 26,
        //     velocity: 10,
        //     precision: 0.01
        // }
    });

    useEffect(() => {}, [])

    // console.log('Dot rendered at ', position)

    return(
        <group>
            <a.mesh 
            // position={position}
            position={[position[0], position[1], position[2]]}
            // onClick={handleActiveView}
            onPointerDown={handleActiveView}
            // scale={springProps.scale}
            onPointerOver={handleOnPointerOver}
            onPointerOut={handleOnPointerOut}
            {...springProps}
            // scale={[1,1,1]}
            >
                <sphereBufferGeometry
                attach="geometry"
                // args={springProps.args}
                args={[6,50,50]}
                // args={[20,50,50]}
                />
                <meshPhongMaterial
                attach="material"
                // Testing by color if updated
                color={"white"}
                // color={`rgb(${colorTone},${colorTone},${colorTone})`}
                emissive={"0x0"}
                specular={"0x111111"}
                // specular={"white"}
                shininess={100}
                depthTest={true}
                transparent={true}
                opacity={0.8}
                // opacity={1}
                envMaps="refraction"
                // envMaps="reflection"
                envMap={scene.background}
                reflectivity={1}
                refractionRatio={0.5}
                // color={hover ? "white" : "red"}
                />
            </a.mesh>
        </group>
    )
}

export default InteractiveDot
        